import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Carousel,
  Image,
} from 'react-bootstrap';

import * as config from '../config.js';
import { BrandingContext } from '../libs/contexts.js';

export const SideBarMedia = () => {

    const { branding } = useContext(BrandingContext);

    return (
        <>
            <CarouselViewer />
        </>
    );

}

const CarouselViewer = ({ }) => {

    const { branding } = useContext(BrandingContext)
    const [maps, setMaps] = useState({});
    const [photos, setPhotos] = useState([]);
    const [hasMaps, setHasMaps] = useState(false);
    const [hasPhotos, setHasPhotos] = useState(false);
    const [videoId, setVideoId] = useState(false);
    useEffect(() => {

        if (branding) {
            if (branding.maps) {
                let newMaps = {};
                for (let [name, mapInfo] of Object.entries(branding.maps)) {
                    if (Array.isArray(mapInfo)) {
                        newMaps[name] = mapInfo;
                    } else {
                        newMaps[name] = mapInfo.split(",");
                    }
                }
                setHasMaps(true);
                setMaps(newMaps);
            }
            if (branding.photos) {
                let newPhotos = branding.photos;
                setHasPhotos(true);
                setPhotos(newPhotos);
            }
            if (branding.video_link) {
                if (branding.video_link.length > 1) {
                    setVideoId(branding.video_link);
                }
            }
        }

    }, [branding]);


    return (
        <Carousel slide={ false } controls={ false } indicators={ false } interval={ 13000 } fade>

            { videoId &&
                <Carousel.Item>
                    <VideoItem videoId={ videoId } />
                </Carousel.Item>
            }

            { hasPhotos && photos.map((name, index) => (

                <Carousel.Item key={ index }>
                    <Image className="mt-1" src={ config.assets + '/' + name } style={ { border: 0, height: "250px" } } />
                </Carousel.Item>

            )) }

            { hasMaps && Object.keys(maps).map((name, index) => (

                <Carousel.Item key={ index }>
                    <MapItem keywords={ maps[name] } zoom={ 11 } />
                </Carousel.Item>

            )) }



        </Carousel>
    )

}


const MapItem = ({ keywords, zoom }) => {

    const [mapSrc, setMapSrc] = useState('');

    useEffect(() => {

        if (keywords) {


            let keywordItems = [];
            for (let keyword of keywords) {
                keywordItems.push(keyword.replace(/\s+/g, '+'));
            }

            let newSrc = ['https://www.google.com/maps/embed/v1/place?key='];
            newSrc.push(config.googleApiKey);
            newSrc.push('&q=');
            newSrc.push(keywordItems.join(","));
            newSrc.push("&zoom=");
            if (undefined === zoom) {
                zoom = 9;
            }
            newSrc.push(zoom);
            setMapSrc(newSrc.join(""));

        }

    }, [keywords, zoom]);

    return (
        <>
            <iframe referrerPolicy="no-referrer-when-downgrade"
                style={ { backgroundColor: "red", border: 0, width: "100%", height: "250px" } }
                src={ mapSrc }
                allowFullScreen></iframe>
        </>
    );
}

const VideoItem = ({ videoId }) => {
    return (
        <>
            { videoId &&
                <div className="videoWrapper">
                    <iframe className='brokerVideo' src={ "https://www.youtube.com/embed/" + videoId } title="YouTube video player" allowFullScreen></iframe>
                </div>
            }
        </>

    );
}
