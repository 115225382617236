import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Image,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import { AiOutlineLock } from 'react-icons/ai';
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegWindowClose,
} from 'react-icons/fa';
import { IoOpenOutline } from 'react-icons/io5';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import { SideBarMedia } from '../controls/sidebar-media.jsx';
import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../libs/contexts.js';
import { calculateScenarios } from '../libs/insurance.js';

export const BaseDesktopFaq = () => {

    const { layout, setLayout } = useContext(LayoutContext);


    return (
        <Container>
            <Row>
                <hr />
                <Col>
                    <h5 id="faq-title">Frequently Asked Questions
                        <small className="float-end float-right clicker">
                            <FaRegWindowClose onClick={ () => setLayout({ faq: "no" }) } />
                        </small>

                    </h5>

                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>What's a premium waiver for total disability?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        A waiver of premium for total disability is an optional benefit provision that is designed to waive the premium if the life insured becomes totally disabled prior to attaining age 60 and remains disabled for 4 consecutive months.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>I'm a smoker. Is that a problem?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        Smoking does not disqualify you from getting insured but the premium will be adjusted to account for the additional risk.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>It says that the price is guaranteed for the entire term. What happens after that?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        You can remain insured but your monthly premiums will likely increase annually at the end of the 10 or 20-year term period. Don't worry, you will be notified ahead of time, and you can see the details of the rate increase in the premium schedule as part of your life policy contract.
                    </p>
                </Col>
            </Row>
            <Row className="mb-4">
                <hr />
                <Col>
                    <Button onClick={ () => setLayout({ faq: "no" }) } variant="outline-primary">Close</Button>
                </Col>
            </Row>
        </Container>
    );

}

export const BaseDesktopFooter = () => {

    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);
    const [footerLinks, setFooterLinks] = useState([]);
    const [colourTheme, setColourTheme] = useState("light");

    useEffect(() => {
        let newFooterLinks = [];
        if (branding.privacyLink || branding.accessibilityLink || branding.ftcLink || branding.tcLink) {
            if (branding.privacyLink && branding.privacyLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Legal/Privacy", url: branding.privacyLink });
            }
            if (branding.accessibilityLink && branding.accessibilityLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Accessibility policy", url: branding.accessibilityLink });
            }
            if (branding.ftcLink && branding.ftcLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Fair treatment of customers policy", url: branding.ftcLink });
            }
            if (branding.tcLink && branding.tcLink.startsWith("https:")) {
                newFooterLinks.push({ text: "Terms and conditions", url: branding.tcLink });
            }
            setFooterLinks(newFooterLinks);
        }
    }, [branding]);

    // useEffect(() => {
    //     if (["light", "dark"].includes(colourTheme)) {
    //         let element = document.querySelector("html");
    //         element.setAttribute("data-bs-theme", colourTheme);
    //         //todo figure out what else we need to switch 
    //     }
    // }, [colourTheme]);

    // const switchColour = (e) => {
    //     e.preventDefault();
    //     if ("dark" === colourTheme) {
    //         setColourTheme("light");
    //     } else {
    //         setColourTheme("dark");
    //     } 
    // }
    return (
        <>
            <Container fluid className="py-3 mt-auto">
                <Row className="footer-area">
                    <Col>
                        <Container>
                            <Row className='mt-4 mb-2'>
                                <Col className='text-center footer-secure'>
                                    <AiOutlineLock /> This connection is secure
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    { footerLinks.map((footerLink, index) => (
                                        <span key={ index }>
                                            { index > 0 &&
                                                <>&nbsp;|&nbsp;</>
                                            }
                                            <a className="footer-link" target="_blank" rel="noopener noreferrer" href={ footerLink.url }>{ footerLink.text }</a>
                                        </span>
                                    )) }
                                    {/* <span>
                                        <>&nbsp;|&nbsp;</>
                                        
                                        <a className="footer-link" href="#" onClick={switchColour}>Switch to {colourTheme==="light" ? "dark" : "light"} theme</a>
                                    </span> */}
                                </Col>
                            </Row>
                            <Row className='mt-1 mb-4'>
                                <Col className='text-center'>
                                    <small>
                                        Available only for Canadian residents
                                        &nbsp;--&nbsp;
                                        <i>Offert uniquement aux résidents Canadiens</i>
                                    </small>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export const BaseDesktopHeader = () => {

    const { branding } = useContext(BrandingContext);


    return (
        <>
            { "header" === branding.logoPosition ?
                <div className="broker-logo">
                    <a target="_blank" href={ branding.website } rel="noopener noreferrer">
                        <Image className="broker-logo" src={ config.assets + "/logo.png" } />
                    </a>
                </div>
                :
                <></>
            }

            <Container>
                <Row className="mt-2 mb-3">

                </Row>
            </Container>


        </>
    );
}

export const BaseDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);

    const { branding } = useContext(BrandingContext);


    const swapTheme = () => {

        //let elem = document.querySelector('html');

        if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
            document.documentElement.setAttribute('data-bs-theme', 'light')
        }
        else {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        }


    }

    return (
        // <Container fluid className="bg-dark text-white">
        <Container fluid className="menu">
            <Row>
                <Col className='desktop-menu '>
                    <Container fluid={ "menu" === branding.logoPosition }>
                        <Row>
                            <Col>
                                { "menu" === branding.logoPosition ?
                                    <>
                                        <div className="broker-logo">
                                            <a target="_blank" href={ branding.website } rel="noopener noreferrer">
                                                <Image className="broker-logo ml-0 ml-lg-1 f-black" src={ config.assets + "/logo.png" } />
                                            </a>
                                        </div>
                                    </>
                                    :
                                    <>&nbsp;</>
                                }
                            </Col>
                            <Col className="text-end text-right">
                                { "button" === branding.menuPhone &&
                                    <a className="btn btn-primary phone-button" href={ layout.phoneHref ? layout.phoneHref.href : "#" }>{ layout.phone }</a>
                                }
                                { "text" === branding.menuPhone &&
                                    <>questions? { layout.phone } or { layout.email }</>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            { "steady" !== branding.progressMode &&
                <Row className="mt-0">

                    <Col className="px-0 mx-0">

                        { "jump" !== layout.specialView && !['policy', 'thankyou', 'opportunities'].includes(layout.currentScreen) &&
                            <>
                                <BaseDesktopProgressBar />
                            </>
                        }
                    </Col>
                </Row>
            }
        </Container>
    );

}

export const BaseDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState();
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);
    const [message, setMessage] = useState("");

    const { branding } = useContext(BrandingContext);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount);
            //onsole.info("PB", screenDiv, screenCount, layout.screenSequence.length);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if ((layout.quoterView && "needs" === layout.quoterView) || ['thankyou', 'opportunities'].includes(layout.currentScreen) || 'policy' === layout.currentScreen || 'consent' === layout.currentScreen) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView, layout.currentScreen]);


    useEffect(() => {

        if ("steady" !== branding.progressMode) {

            if (progress <= 10) {
                setColor('primary');
            } else {
                if (progress > 10 && progress <= 40) {
                    setColor('info');
                } else {
                    if (progress > 40 && progress <= 80) {
                        setColor('warning');
                    } else {
                        setColor('success');
                    }
                }
            }
        }

    }, [progress]);

    return (
        <>
            { isVisible && progress && progress >= 1 &&
                <>
                    { "steady" === branding.progressMode ?
                        <Container>
                            <Row className="mt-4">
                                <Col className="col-10">
                                    <ProgressBar className="custom-progress" title="If you complete the process, you will be insured in a matter of minutes" variant="secondary" now={ progress } label={ `${progress}%` } />
                                </Col>
                            </Row>
                        </Container>

                        :
                        <ProgressBar className="full-square" title="If you complete the process, you will be insured in a matter of minutes" animated variant={ color } now={ progress } label={ `${progress}%` } />
                    }

                </>

            }
        </>
    )

}

export const BaseDesktopSidebar = () => {


    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [optionsHaveChanged, setOptionsHaveChanged] = useState(false);
    const [yearsLocked, setYearsLocked] = useState("");

    const [hasPremium, setHasPremium] = useState(false);
    const [hasOptions, setHasOptions] = useState(false);

    const [helperView, setHelperView] = useState('buttons');

    const navigate = useNavigate();


    useEffect(() => {

        if ("no" === layout.faq) {
            if (!["phone", "email"].includes(helperView)) {
                setHelperView('buttons');
            }
        }

    }, [layout.faq, helperView]);

    useEffect(() => {

        //onsole.log("Options have changed", optionsHaveChanged, session.term_selected, session.rider, session.offers, session.facevalue);
        if (optionsHaveChanged) {
            let premiumOptions = calculateScenarios({ currentTerm: session.term_selected, rider: session.rider, offers: session.offers, facevalue: session.facevalue });
            setSession(premiumOptions);
            setOptionsHaveChanged(false);
        }

    }, [optionsHaveChanged, session.term_selected, session.rider, session.offers, session.facevalue]);

    useEffect(() => {


        if (undefined !== session.premium && null !== session.premium && undefined !== session.term_selected && null !== session.term_selected) {

            if ("needs" !== layout.quoterView) {
                setHasPremium(true);
                setYearsLocked("for " + session.term_selected.replace('term', '') + " years");

            } else {
                setHasPremium(false);
            }

        } else {
            setHasPremium(false);
            setHasOptions(false)
        }

    }, [session.premium, session.term_selected, layout.quoterView]);

    useEffect(() => {



        if (layout.currentScreen) {

            //onsole.log("Changing current screen", layout);

            if (["welcome", "quoter", "landing"].includes(layout.currentScreen)) {

                setHasOptions(false);


            } else {
                //onsole.info("Setting hasOptions");
                if ("needs" === layout.quoterView) {
                    setHasOptions(false);
                    //  onsole.info("Setting hasOptions", false);
                } else {
                    setHasOptions(true);
                    //onsole.info("Setting hasOptions", true);
                }
            }

        }
    }, [layout.currentScreen, layout.quoterView]);


    const toggleHelper = (button) => {

        if (button === helperView) {
            setHelperView('buttons');
            if ("faq" === button) {
                setLayout({
                    faq: "no"
                })
            }
        } else {
            setHelperView(button);
            if ("faq" === button) {
                setLayout({
                    faq: "yes"
                })
            }
        }



    }

    const toggleRider = () => {

        let newSession = {
            rider: "Yes" === session.rider ? "No" : "Yes"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);


    }


    const toggleTerm = () => {

        let newSession = {
            term_selected: "term10" === session.term_selected ? "term20" : "term10"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);

    }



    const reviewContactInfo = () => {

        setLayout({
            currentScreen: 'welcome'
        });
        navigate('/welcome');

    }

    return (
        <>
            <Container className="mt-4" fluid>
                { hasPremium &&
                    <Row className='mt-1'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title><h3>{ session.premium } <small>per month</small></h3></Card.Title>
                                    <Card.Text>
                                        This price is guaranteed { yearsLocked }. Cancel anytime.
                                    </Card.Text>

                                    { hasOptions && !['welcome', 'quoter', 'consent', 'policy'].includes(layout.currentScreen) &&
                                        <Dropdown>
                                            <Dropdown.Toggle className='plan-picker' variant="success" id="dropdown-basic">
                                                Adjust your policy
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={ toggleRider } >{ "Yes" === session.rider ?
                                                    <>Remove</>
                                                    :
                                                    <>Add</>
                                                } total disability waiver ({ session.premium_rider })</Dropdown.Item>
                                                <Dropdown.Item onClick={ toggleTerm }>Switch to a { session.alternative_years } year price guarantee ({ session.alternative_total })</Dropdown.Item>
                                                <Dropdown.Item as={ Link } to="/quoter">Change your coverage</Dropdown.Item>
                                                <Dropdown.Item as={ Link } to="/welcome">Review your contact information</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                    { 'payment' === layout.currentScreen &&
                                        <>
                                            <Alert variant="success" className="mt-2">
                                                <Alert.Heading>Zero worries.</Alert.Heading>
                                                <p>
                                                    Recurring payments are not scheduled by robots. One of our experienced team members will review every detail of your profile
                                                    and will setup the transaction manually. And remember: you can <b>cancel any time</b>.
                                                </p>
                                                <hr />
                                                <small>
                                                    Should you have any concern, the customer service experts at { branding.display } are there for you. Feel free to reach out!
                                                </small>
                                            </Alert>
                                        </>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    This is a <b>{ session.premium_coverage }</b> policy
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row className="mt-4">
                    <Col>

                        <Card className={ "steady" === branding.progressMode ? "mt-4 border-0" : "mt-4 border-0" }>

                            <div className="d-none d-lg-block d-xl-block d-xxl-block mx-auto">
                                <SideBarMedia />
                                {/* <div className="videoWrapper">
                                        <iframe className='brokerVideo' src={"https://www.youtube.com/embed/" + branding.introduction} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                                    </div> */}
                            </div>

                            <Card.Body className="px-0">
                                <Container fluid>
                                    <Row className='mb-2'>
                                        <Col className="text-center">
                                            { layout.emailHref ?
                                                <a href={ layout.emailHref.href }>{ layout.email }</a>
                                                :
                                                <>{ layout.email }</>
                                            }
                                            {/* {session.first_name ?
                                                <>
                                                    Any questions, {session.first_name} ?
                                                </>
                                                : <>
                                                    Questions?
                                                </>
                                            } */}

                                        </Col>
                                    </Row>

                                    <Row className='text-center'>
                                        {/* <Col className='px-0'>
                                            <Button onClick={() => toggleHelper('phone')} className='full-square' variant={"phone" === helperView ? "outline-primary" : "outline-info"}>
                                                <BsTelephone />
                                                <small className="float-right float-end">

                                                    {"phone" === helperView ?
                                                        <BsChevronUp />
                                                        :
                                                        <BsChevronDown />
                                                    }

                                                </small>
                                            </Button>
                                        </Col>
                                        <Col className='px-0'>
                                            <Button onClick={() => toggleHelper('email')} className='full-square' variant={"email" === helperView ? "outline-primary" : "outline-info"}>
                                                <HiOutlineMail />
                                                <small className="float-right float-end">
                                                    {"email" === helperView ?
                                                        <BsChevronUp />
                                                        :
                                                        <BsChevronDown />
                                                    }
                                                </small>
                                            </Button>
                                        </Col> */}
                                        <Col className='px-0'>
                                            <Button onClick={ () => toggleHelper('faq') } className='full-square' variant="outline-info">
                                                Questions?
                                                <small className="float-right float-end">
                                                    { "faq" === helperView ?
                                                        <FaRegWindowClose />
                                                        :
                                                        <></>
                                                    }
                                                </small>
                                            </Button>
                                        </Col>

                                    </Row>
                                    { "phone" === helperView &&
                                        <Row className='mt-2 text-center'>
                                            <Col>{ layout.phone }</Col>
                                        </Row>
                                    }

                                    { "email" === helperView &&
                                        <Row className='mt-2 text-center'>
                                            <Col>{ layout.email } <a title="Send email now" href={ "mailto:" + layout.email }><IoOpenOutline /></a></Col>
                                        </Row>
                                    }

                                </Container>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    )

}

export const BaseDesktopPremiumBox = () => {


    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [optionsHaveChanged, setOptionsHaveChanged] = useState(false);
    const [yearsLocked, setYearsLocked] = useState("");

    const [hasPremium, setHasPremium] = useState(false);
    const [hasOptions, setHasOptions] = useState(false);

    const [helperView, setHelperView] = useState('buttons');

    const navigate = useNavigate();


    useEffect(() => {

        //onsole.log("Options have changed", optionsHaveChanged, session.term_selected, session.rider, session.offers, session.facevalue);
        if (optionsHaveChanged) {
            let premiumOptions = calculateScenarios({ currentTerm: session.term_selected, rider: session.rider, offers: session.offers, facevalue: session.facevalue });
            setSession(premiumOptions);
            setOptionsHaveChanged(false);
        }

    }, [optionsHaveChanged, session.term_selected, session.rider, session.offers, session.facevalue]);

    useEffect(() => {


        if (undefined !== session.premium && null !== session.premium && undefined !== session.term_selected && null !== session.term_selected) {

            if ("needs" !== layout.quoterView) {
                setHasPremium(true);
                setYearsLocked("for " + session.term_selected.replace('term', '') + " years");

            } else {
                setHasPremium(false);
            }
        } else {
            setHasPremium(false);
            setHasOptions(false)
        }

    }, [session.premium, session.term_selected, layout.quoterView]);

    useEffect(() => {



        if (layout.currentScreen) {

            //onsole.log("Changing current screen", layout);

            if (["welcome", "quoter", "landing"].includes(layout.currentScreen)) {

                setHasOptions(false);


            } else {
                //onsole.info("Setting hasOptions");
                if ("needs" === layout.quoterView) {
                    setHasOptions(false);
                    //  onsole.info("Setting hasOptions", false);
                } else {
                    setHasOptions(true);
                    //onsole.info("Setting hasOptions", true);
                }
            }

        }
    }, [layout.currentScreen, layout.quoterView]);



    const toggleRider = () => {

        let newSession = {
            rider: "Yes" === session.rider ? "No" : "Yes"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);


    }


    const toggleTerm = () => {

        let newSession = {
            term_selected: "term10" === session.term_selected ? "term20" : "term10"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);

    }



    const reviewContactInfo = () => {

        setLayout({
            currentScreen: 'welcome'
        });
        navigate('/welcome');

    }

    return (<>
        { hasPremium &&
            <Container fluid>
                <Row className='mt-4'>
                    <Col>
                        <Card className="premium-card">
                            <Card.Body>
                                <Card.Title><h3>{ session.premium } <small>per month</small></h3></Card.Title>
                                <Card.Text>
                                    This price is guaranteed { yearsLocked }. Cancel anytime.
                                </Card.Text>

                                { hasOptions && !['welcome', 'quoter', 'consent', 'policy'].includes(layout.currentScreen) &&
                                    <Dropdown>
                                        <Dropdown.Toggle className='plan-picker' variant="success" id="dropdown-basic">
                                            Adjust your policy
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={ toggleRider } >{ "Yes" === session.rider ?
                                                <>Remove</>
                                                :
                                                <>Add</>
                                            } total disability waiver ({ session.premium_rider })</Dropdown.Item>
                                            <Dropdown.Item onClick={ toggleTerm }>Switch to a { session.alternative_years } year price guarantee ({ session.alternative_total })</Dropdown.Item>
                                            <Dropdown.Item as={ Link } to="/quoter">Change your coverage</Dropdown.Item>
                                            <Dropdown.Item as={ Link } to="/welcome">Review your contact information</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                { 'payment' === layout.currentScreen &&
                                    <>
                                        <Alert variant="success" className="mt-2">
                                            <Alert.Heading>Zero worries.</Alert.Heading>
                                            <p>
                                                Recurring payments are not scheduled by robots. One of our experienced team members will review every detail of your profile
                                                and will setup the transaction manually. And remember: you can <b>cancel any time</b>.
                                            </p>
                                            <hr />
                                            <small>
                                                Should you have any concern, the customer service experts at { branding.display } are there for you. Feel free to reach out!
                                            </small>
                                        </Alert>
                                    </>
                                }
                            </Card.Body>
                            <Card.Footer>
                                This is a <b>{ session.premium_coverage }</b> policy
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        }
    </>);
}

export const BaseDesktopFaqTrigger = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);
    const [helperView, setHelperView] = useState('buttons');

    useEffect(() => {

        if ("no" === layout.faq) {
            if (!["phone", "email"].includes(helperView)) {
                setHelperView('buttons');
            }
        }

    }, [layout.faq, helperView]);


    const toggleHelper = (button) => {

        if (button === helperView) {
            setHelperView('buttons');
            if ("faq" === button) {
                setLayout({
                    faq: "no"
                })
            }
        } else {
            setHelperView(button);
            if ("faq" === button) {
                setLayout({
                    faq: "yes"
                })
            }
        }



    }

    return (<>
        <Button onClick={ () => toggleHelper('faq') } variant="outline-info">
            { "faq" === helperView ?
                <FaChevronLeft />
                :
                <FaChevronRight />
            } Frequently asked questions
        </Button>
    </>);
}