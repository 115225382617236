import {
  React,
  useContext,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { LayoutContext } from '../libs/contexts';

export const PolicyOngoing = ({ phase }) => {

    return (
        <>
            <Container fluid>
                <Row className='mt-4'>
                    <Col className="col-md-1">
                        <Spinner animation="border" role="status" />
                    </Col>
                    <Col>
                        We are reviewing your answers, this should take less than 5 minutes.
                    </Col>
                </Row>

            </Container>

        </>
    )
}



export const PolicyDelayed = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            <Container fluid>
                <Row className='mt-4'>
                    <Col>
                        <p>Generating the policy is taking longer than usual.</p>
                        <p>Please contact us to discuss the situation: { layout.phone } or { layout.email }</p>
                        <p>Rest assured that there will be no payment scheduled until the situation is resolved.</p>
                    </Col>
                </Row>

            </Container>

        </>
    )
}

export const PolicyFailed = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            <Container fluid>
                <Row className='mt-4'>
                    <Col>
                        <p>Unfortunately, we are unable to generate your policy at this time. </p>
                        <p>Please contact us to discuss the situation: { layout.phone } or { layout.email }</p>
                        <p>Rest assured that there will be no payment scheduled for this quote.</p>
                    </Col>
                </Row>

            </Container>

        </>
    )
}

