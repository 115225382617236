export const ageMethod = "age nearest";
export const gateway = "https://oraclerms-api.link.wawanesalife.com";
export const assets = "https://stable-assets.link.wawanesalife.com/brokers/oraclerms";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const appToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvcmFjbGVybXMiLCJuYW1lIjoib3JhY2xlcm1zIiwiaWF0IjoxNzQxODI3NzkxLCJleHAiOjE4OTk2MTU3OTF9.dZptoJ_jnO67VIdrV1tqhiDPc4-AW9GjC3BomsbkRNs";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"oraclerms.link.wawanesalife.com": {
		a: {
			broker: "oraclerms",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#oraclerms.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "life@oraclerms.com",
			map2: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			environment_id: "stable",
			map1: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			brands: [
				"oraclerms.link.wawanesalife.com",
			],
			map4: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			map3: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			"maps": {
				"map1": [
					"100 Drumlin Circle",
					"Concord",
					"ON",
					"L4K 3E5",
				],
			},
			phone: "1-855-884-6150",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.oraclerms.com/",
			layout: "iframe",
			g4: "G-K79QEMGKPC",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "oraclerms.link.wawanesalife.com",
		},
		b: {
			broker: "oraclerms",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#oraclerms.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "life@oraclerms.com",
			map2: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			environment_id: "stable",
			map1: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			brands: [
				"oraclerms.link.wawanesalife.com",
			],
			map4: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			map3: [
				"100 Drumlin Circle",
				"Concord",
				"ON",
				"L4K 3E5",
			],
			"maps": {
				"map1": [
					"100 Drumlin Circle",
					"Concord",
					"ON",
					"L4K 3E5",
				],
			},
			phone: "1-855-884-6150",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.oraclerms.com/",
			layout: "iframe",
			g4: "G-K79QEMGKPC",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "oraclerms.link.wawanesalife.com",
		},
	},
};

//updated on Wed Mar 12 2025 20:03:11 GMT-0500 (Central Daylight Time)
